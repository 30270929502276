window.$ = window.jQuery = require('jquery');
import "linkify-jquery";

import Cookies from "js-cookie/src/js.cookie.js"
window.Cookies = Cookies;

import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

import "numeral/locales/de";
import numeral from "numeral";
numeral.locale('de');
numeral.defaultFormat("0,0.00");
window.numeral = numeral;

window.IBAN = require('iban');

import "./inc/light.js"
import "./inc/fontawesome.js"
import "mustache/mustache.js"
import "bootstrap/dist/js/bootstrap.bundle.js"
import "bootstrap-datepicker/dist/js/bootstrap-datepicker.js"
import "bootstrap-datepicker/dist/locales/bootstrap-datepicker.de.min.js"
import "bootstrap-select/dist/js/bootstrap-select.js"
import "bootstrap-select/dist/js/i18n/defaults-de_DE.js"
import "bootstrap/dist/js/bootstrap.bundle.js"
import "parsleyjs/dist/parsley.js"
import "parsleyjs/dist/i18n/de.js"
import "parsleyjs/dist/i18n/de.extra.js"
import "moment"
import flatpickr from "flatpickr";
import "./modules/flexslider/jquery.flexslider-min";

import { German } from "flatpickr/dist/l10n/de"

flatpickr.localize(German);

$('.flatpickr-date').flatpickr({
    allowInput: true,
    dateFormat: "d.m.Y",
    weekNumbers: true
});

$('.flatpickr-datetime').flatpickr({
    allowInput: true,
    dateFormat: "d.m.Y H:i",
    weekNumbers: true,
    enableTime: true,
});

$('.datepicker').datepicker({
    format: "dd.mm.yyyy",
    weekStart: 1,
    language: "de",
    zIndexOffset: 1050,
    enableOnReadonly: false
});
$('.input-daterange').datepicker();
$('.bs-select').selectpicker({
    size: 10,
});
$('#page-header .navbar-toggler').click(function ()
{
    $(this).toggleClass('active');
});

function addFlashMessage(message, level, targetSelector)
{
    var messageHtml = '<div class="alert alert-' + (level || 'info') + '">' + message + '</div>';
    $('#flash-message-container .alert:not(.peristent)').remove();
    $((targetSelector || '#flash-message-container')).append(messageHtml);
}

$('[data-toggle="tooltip"]').tooltip({
    container: "body",
});
$('#cookie-note__accept-button').click(function ()
{
    Cookies.set('isCookiesAccepted', 'true', {expires: 7});
    $('#cookie-note').remove();
});